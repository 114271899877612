<template>
  <div>
    <!-- <v-container fluid class="down-top-padding"> -->
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <v-form ref="form" lazy-validation>
          <v-layout wrap>
            <v-flex xs12 pt-2>
              <v-card>
                <v-card-title class="elevation-1">
                  <span v-if="$route.query.type == 'client'">
                    {{ baseData.client }} Affiliation Report
                  </span>
                  <span v-else>
                    {{ baseData.affiliator }} Affiliation Report
                  </span>
                  <v-spacer></v-spacer>
                  <v-flex xs12 sm2 pt-7 pl-3>
                    <v-menu
                      v-model="menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fromDate"
                          label="From Date"
                          append-icon="mdi-calendar"
                          readonly
                          outlined
                          dense
                          clearable
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fromDate"
                        @input="menu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex xs12 sm2 pt-7 pl-3>
                    <v-menu
                      v-model="menu1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="toDate"
                          label="To Date"
                          append-icon="mdi-calendar"
                          readonly
                          outlined
                          dense
                          clearable
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="toDate"
                        @input="menu1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex xs12 sm2 pl-3 v-if="$route.query.type != 'client'">
                    <v-btn dark color="green" :href="ipURL + '/get/affiliation/commission/report/affiliator/v1?affiliator=' + $route.query.id + '&fromDate=' + fromDate + '&toDate=' + toDate"
                      target="_blank"
                      >Download PDF</v-btn
                    >
                  </v-flex>
                  <v-flex xs12 v-if="$route.query.type == 'client'">
                    <v-card outlined style="background-color: #fcfcfc">
                      <v-layout wrap justify-start pa-5>
                        <v-flex xs12 text-left>
                          <span
                            style="
                              font-size: 16px;
                              color: #000000;
                              text-transform: uppercase;
                            "
                            >Available coins</span
                          >
                        </v-flex>
                        <v-flex xs12 sm12 md8 py-2>
                          <v-layout wrap>
                            <!-- <v-flex xs2 sm1 md1 pt-1 text-left>
                                <v-img
                                  contain
                                  height="20px"
                                  width="20px"
                                  src="./../../assets/coin.png"
                                ></v-img>
                              </v-flex> -->
                            <v-flex xs4 sm4 md3 lg2 text-left>
                              <span style="font-size: 20px; color: #ff2323">{{
                                baseData.coins
                              }}</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12>
                          <v-progress-linear
                            height="5"
                            rounded
                            color="#ff2323"
                            :value="total"
                          ></v-progress-linear>
                        </v-flex>
                        <v-flex xs12 pt-2>
                          <v-layout wrap>
                            <v-flex xs12 sm6 text-left>
                              <span
                                style="
                                  color: #2e2e2e;
                                  text-transform: uppercase;
                                  font-size: 13px;
                                "
                                >Total earned : {{ baseData.coinsgained }}</span
                              >
                            </v-flex>
                            <v-flex xs12 sm6 text-right>
                              <span
                                style="
                                  color: #2e2e2e;
                                  text-transform: uppercase;
                                  font-size: 13px;
                                "
                                >Total Spent : {{ baseData.coinsspend }}</span
                              >
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                  <v-flex xs12 v-else>
                    <v-card outlined style="background-color: #fcfcfc">
                      <v-layout wrap justify-start pa-5>
                        <v-flex xs12 sm6 md10 text-left>
                          <span
                            style="
                              font-size: 16px;
                              color: #000000;
                              text-transform: uppercase;
                            "
                            >Available Amount</span
                          >
                        </v-flex>
                        <v-flex xs12 sm6 md2 text-right>
                          <v-btn small color="red" dark @click="dialog = true"
                            >Settle</v-btn
                          >
                        </v-flex>
                        <v-flex xs12 sm12 md8 py-2>
                          <v-layout wrap>
                            <!-- <v-flex xs2 sm1 md1 pt-1 text-left>
                                <v-img
                                  contain
                                  height="20px"
                                  width="20px"
                                  src="./../../assets/coin.png"
                                ></v-img>
                              </v-flex> -->
                            <v-flex xs4 sm4 md3 lg2 text-left>
                              <span style="font-size: 20px; color: #ff2323">{{
                                baseData.commissionAmountLefftobeCollected
                              }}</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12>
                          <v-progress-linear
                            height="5"
                            rounded
                            color="#ff2323"
                            :value="totalAff"
                          ></v-progress-linear>
                        </v-flex>
                        <v-flex xs12 pt-2>
                          <v-layout wrap>
                            <v-flex xs12 sm6 text-left>
                              <span
                                style="
                                  color: #2e2e2e;
                                  text-transform: uppercase;
                                  font-size: 13px;
                                "
                                >Total earned :
                                {{ baseData.totalCommissionAmount }}</span
                              >
                            </v-flex>
                            <v-flex xs12 sm6 text-right>
                              <span
                                style="
                                  color: #2e2e2e;
                                  text-transform: uppercase;
                                  font-size: 13px;
                                "
                                >Total Settle :
                                {{ baseData.commissionAmountcollected }}</span
                              >
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </v-card-title>
                <v-layout wrap v-if="$route.query.type == 'client'">
                  <v-flex xs12>
                    <v-data-table
                      :headers="navItems"
                      :items="list"
                      hide-default-footer
                      class="elevation-1"
                    >
                      <template v-slot:[`item.user`]="{ item }">
                        <span
                          >{{ item.buyer.firstname }}
                          {{ item.buyer.lastname }}</span
                        >
                      </template>
                      <template v-slot:[`item.create_date`]="{ item }">
                        <span>{{ item.create_date.slice(0, 10) }}</span>
                      </template>
                      <template v-slot:[`item.affiliator`]="{ item }">
                        <span v-if="item.affiliator"
                          >{{ item.affiliator.firstname }}
                          {{ item.affiliator.lastname }}</span
                        >
                      </template>
                      <template v-slot:[`item.coins`]="{ item }">
                        <span v-if="$route.query.type == 'client'">{{
                          item.coins
                        }}</span>
                      </template>
                      <template v-slot:[`item.commission_amt`]="{ item }">
                        <span v-if="$route.query.type == 'seller'">{{
                          item.commission_amt
                        }}</span>
                      </template>
                      <!-- <template v-slot:[`item._id`]="{ item }">
                          <v-btn small color="red" dark @click="addSettle(item)"
                            >Settle</v-btn
                          >
                        </template> -->
                    </v-data-table>
                  </v-flex>
                </v-layout>
                <v-layout wrap v-else>
                  <v-flex xs12>
                    <v-tabs
                      background-color="red"
                      center-active
                      dark
                      fixed-tabs
                      v-model="tab"
                    >
                      <v-tab style="font-weight: bold"
                        >Affiliation Report</v-tab
                      >
                      <v-tab style="font-weight: bold">Settlement Report</v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tab">
                      <v-tab-item>
                        <v-card color="basil" flat>
                          <v-data-table
                            :headers="navItems"
                            :items="list"
                            hide-default-footer
                            class="elevation-1"
                          >
                            <template v-slot:[`item.user`]="{ item }">
                              <span
                                >{{ item.buyer.firstname }}
                                {{ item.buyer.lastname }}</span
                              >
                            </template>
                            <template v-slot:[`item.create_date`]="{ item }">
                              <span>{{ item.create_date.slice(0, 10) }}</span>
                            </template>
                            <template v-slot:[`item.affiliator`]="{ item }">
                              <span v-if="item.affiliator"
                                >{{ item.affiliator.firstname }}
                                {{ item.affiliator.lastname }}</span
                              >
                            </template>
                            <template v-slot:[`item.coins`]="{ item }">
                              <span v-if="$route.query.type == 'client'">{{
                                item.coins
                              }}</span>
                            </template>
                            <template v-slot:[`item.commission_amt`]="{ item }">
                              <span v-if="$route.query.type == 'seller'">{{
                                item.commission_amt
                              }}</span>
                            </template>
                            <!-- <template v-slot:[`item._id`]="{ item }">
                          <v-btn small color="red" dark @click="addSettle(item)"
                            >Settle</v-btn
                          >
                        </template> -->
                          </v-data-table>
                          <div class="pt-2" v-if="pages > 1">
                            <v-pagination
                              :length="pages"
                              :total-visible="7"
                              v-model="currentPage"
                              color="#FF0000"
                            ></v-pagination>
                          </div>
                        </v-card>
                      </v-tab-item>
                      <v-tab-item>
                        <v-card color="basil" flat>
                          <v-data-table
                            :headers="settleData"
                            :items="report"
                            hide-default-footer
                            class="elevation-1"
                          >
                            <template v-slot:[`item.user`]="{ item }">
                              <span
                                >{{ item.buyer.firstname }}
                                {{ item.buyer.lastname }}</span
                              >
                            </template>
                            <template v-slot:[`item.create_date`]="{ item }">
                              <span>{{ item.create_date.slice(0, 10) }}</span>
                            </template>
                            <template v-slot:[`item.affiliator`]="{ item }">
                              <span v-if="item.affiliator"
                                >{{ item.affiliator.firstname }}
                                {{ item.affiliator.lastname }}</span
                              >
                            </template>
                            <template v-slot:[`item.coins`]="{ item }">
                              <span v-if="$route.query.type == 'client'">{{
                                item.coins
                              }}</span>
                            </template>
                            <template v-slot:[`item.commission_amt`]="{ item }">
                              <span v-if="$route.query.type == 'seller'">{{
                                item.commission_amt
                              }}</span>
                            </template>
                          </v-data-table>
                          <div class="pt-2" v-if="pages > 1">
                            <v-pagination
                              :length="pages"
                              :total-visible="7"
                              v-model="currentPage"
                              color="#FF0000"
                            ></v-pagination>
                          </div>
                        </v-card>
                      </v-tab-item>
                    </v-tabs-items>
                  </v-flex>
                </v-layout>
              </v-card>
              <v-dialog
                v-model="dialog"
                :retain-focus="false"
                max-width="600px"
              >
                <v-card>
                  <v-card-title>
                    <span class="headline">Commission Settle </span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="baseData.commissionAmountLefftobeCollected"
                            outlined
                            dense
                            label="Commission Amount"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialog = false">
                      Cancel
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="settleAmount()">
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-flex>
          </v-layout>
        </v-form>
      </v-flex>
    </v-layout>
    <!-- </v-container> -->
  </div>
</template>
    <script>
import axios from "axios";
export default {
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      dialog: false,
      editingitem: {},
      pageCount: 1,
      appLoading: false,
      currentPage: 1,
      totalData: 0,
      totalRows: 0,
      dialogDelete: false,
      minrange: "",
      maxrange: "",
      tab: "",
      amt: "",
      list: [],
      fromDate: this.$route.query.fromDate,
      toDate: this.$route.query.toDate,
      menu: false,
      menu1: false,
      search: "",
      totalGain: "",
      totalSpend: "",
      total: "",
      totalGainAff: "",
      totalSpendAff: "",
      totalAff: "",
      settleData: [
        { text: "Settlement Date", value: "create_date" },
        { text: "Affiliator", value: "affiliator" },
        { text: "Phone", value: "affiliator.phone" },
        { text: "Email", value: "affiliator.email" },
        { text: "Amount", value: "amount" },
      ],
      clients: [
        { text: "Date", value: "create_date" },
        { text: "User", value: "user" },
        { text: "Affiliator", value: "affiliator" },
        { text: "Order Id", value: "orderId" },
        { text: "Amount (Rs.)", value: "amount" },
        // { text: "Commission Amount", value: "commission_amt" },
        { text: "Coins", value: "coins" },
        //   { text: "Actions", value: "_id", sortable: false },
      ],
      sellers: [
        { text: "Date", value: "create_date" },
        { text: "User", value: "user" },
        { text: "Affiliator", value: "affiliator" },
        { text: "Order Id", value: "orderId" },
        { text: "Amount (Rs.)", value: "amount" },
        { text: "Commission Amount (Rs.)", value: "commission_amt" },
        // { text: "Coins", value: "coins" },
        // { text: "Actions", value: "_id", sortable: false },
      ],
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
      baseData: "",
      report: [],
    };
  },
  computed: {
    navItems() {
      if (this.$route.query.type == "client") return this.clients;
      else return this.sellers;
      // return this.AdminNavigationItems;
    },
  },
  watch: {
    currentPage() {
      this.getData();
      this.getReport();
    },
    fromDate() {
      this.getData();
      this.getReport();
    },
    toDate() {
      this.getData();
      this.getReport();
    },
    search() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
    if (this.$route.query.type == "seller") {
      this.getReport();
    }
  },
  methods: {
    getData() {
      this.appLoading = true;
      var url = "";
      var client = "";
      var affiliator = "";
      if (this.$route.query.type == "client") {
        url = "/get/affiliation/commission/report/client";
        client = this.$route.query.id;
      } else {
        url = "/get/affiliation/commission/report/affiliator";
        affiliator = this.$route.query.id;
      }
      axios({
        url: url,
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          count: this.count,
          page: this.currentPage,
          fromDate: this.fromDate,
          toDate: this.toDate,
          client: client,
          affiliator: affiliator,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.list = response.data.data;
          this.baseData = response.data;
          this.totalData = response.data.count;
          this.totalGain = this.baseData.coinsgained;
          this.totalSpend = this.baseData.coinsspend;
          this.total = 100 - (this.totalSpend / this.totalGain) * 100;
          this.totalGainAff = this.baseData.totalCommissionAmount;
          this.totalSpendAff = this.baseData.commissionAmountcollected;
          this.totalAff = 100 - (this.totalSpendAff / this.totalGainAff) * 100;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    userinfo(uid) {
      this.$router.push("/affiliationView?id=" + uid);
    },
    addSettle(item) {
      this.dialog = true;
      this.editingitem = item;
    },
    settleAmount() {
      var data = {};
      data["affiliatorId"] = this.$route.query.id;
      data["commissionAmount"] =
        this.baseData.commissionAmountLefftobeCollected;
      axios({
        url: "/settle/commission/affiliator",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.dialog = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Settled Sucessfully";
            this.showsnackbar = true;
            this.getData();
            this.getReport();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getReport() {
      this.appLoading = true;
      axios({
        url: "/get/commission/settlement/report/affiliator",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          count: this.count,
          page: this.currentPage,
          fromDate: this.fromDate,
          toDate: this.toDate,
          affiliator: this.$route.query.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.report = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getPDF() {
      this.appLoading = true;
      axios({
        url: "/get/affiliation/commission/report/affiliator/v1",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          // count: this.count,
          // page: this.currentPage,
          fromDate: this.fromDate,
          toDate: this.toDate,
          affiliator: this.$route.query.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
    
<style scoped>
.table-striped:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
    